<template>
	<div class="HomeIndex">
		<div class="top-boxs">
<!-- 			<fileinterspace :maxfilenum="1" v-if="showfilevisible" @selectfileArr="selectfileArrfun" :showfilevisible="showfilevisible" @closedialog="ceshifilekong"></fileinterspace>
			{{selectfileAr}}
			<button @click="ceshifilekong">测试文件空间</button> -->
			<!-- <count-Down
        :countNum="topBoxList.todayInsertCUST"
        class="count color1"
        @click.native="clickAll"
        >新增客户</count-Down
      >
      <count-Down :countNum="topBoxList.COUNTNum" class="count color2"
        >总客户数</count-Down
      >
      <count-Down :countNum="topBoxList.todayInsertOffer" class="count color3"
        >新增报价</count-Down
      >
      <count-Down :countNum="topBoxList.CUNTLoginUserNum" class="count color4"
        >上线人数</count-Down
      > -->
		</div>
	</div>
</template>

<script>
	import fileinterspace from '@/components/fileinterspace/index.vue'
	export default {
		name: 'index',
		components: {
			fileinterspace
		},
		data() {
			return {
				selectfileAr:[],
				showfilevisible: false,
			}
		},
		methods: {
			// 测试文件空间的
			ceshifilekong() {
				this.showfilevisible = !this.showfilevisible
			},
			selectfileArrfun(item){
				console.log("ite数据杀死:",item)
				this.selectfileAr = item;
			}
		},
		mounted() {

		},
		created() {

		},
	}
</script>

<style lang="less" scoped>

</style>
